import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, Tags, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner } from '../components/Section'
import { LetastArticlesBgWhite, CustomTags, SectionBeforefterBg, PageTitle, PageTitleWrapper, CardImg, TraingleShape, CaseStudsyContent, TagWrapper, CaseStudyWrapper} from "../components/CaseStudyStyle"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LetastArticles from "../components/LetastArticles"
import BgBefore from '../images/case-study-before.png'
import BgAfter from '../images/case-study-after.png'
import CaseStudyFeatures from "../components/CaseStudyFeatures"


function caseStudyPage() {
  return (
    <Layout pageName="case-study-detail">
        <SEO title="MCHP Medical Clinics – Bakersville, NC" description="MCHP Medical Clinics – Bakersville, NC" />
        <SectionBeforefterBg pt="156px" pb="100px" xpt="80px" xpb="60px" mpb="40px" bgColor="#F8F8F8" bgBefore={`url(${BgBefore})`} bgAfter={`url(${BgAfter})`}>
        <TraingleShape />
        <div className="container">
            <BreadCrumb>
                <Link to="/">Home</Link> <Link to="/case-studies">Case Studies</Link><Link to="/#">MCHP Medical Clinics – Bakersville, NC</Link>
            </BreadCrumb>
            <PageTitleWrapper>
                <PageTitle className="h2">MCHP Medical Clinics – Bakersville, NC</PageTitle>
                <Tags to="/">Access Control Hardware Installation & Configuration</Tags>
            </PageTitleWrapper>
            <CaseStudyWrapper>
                <CaseStudsyContent>
                    <CardImg>
                        <StaticImage
                        src="../images/case.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                        />
                    </CardImg>
                    <p><strong>Key Issue:</strong> Entries/exits used for employee and vendor access need to be regulated for safety and efficiency.</p>
                    <p><strong>Services Provided:</strong> Access control technology installed for 6 medical/dental clinic locations.</p>
                    <p><strong>Solution Overview:</strong> Access control is networked such that all entries and exits are managed from one central computer.</p>
                    <p><strong>Key Results:</strong> The system has improved security, productivity, and ease of access control management.</p>
                </CaseStudsyContent>
            </CaseStudyWrapper>
        </div>
        </SectionBeforefterBg>
        <Section pt="0" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
            <div className="container">
                <CaseStudyWrapper>
                    <MarkdownContentWrapper>
                    <MarkdownContentWrapperInner>
                        <MarkdownContent>
                            <h2>Services Delivered</h2>
                            <TagWrapper>
                                <CustomTags>Access Control Hardware Installation & Configuration</CustomTags>
                            </TagWrapper>
                            <p>Installation of access control hardware at points of entry. Structured cabling was laid to power the system and network it with a central control center. Access control system was then programmed and configured for use with Active Directory.</p>
                            <ul>
                                <li>De-installation & re-installation of wooden door frames regarding cabling paths.</li>
                                <li>Installation & configuration of structured cabling network.</li>
                                <li>Installation of new door hardware provided by WYN Technologies.</li>
                                <li>Updated system provides real-time monitoring of employee/vendor movement throughout all facilities.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Problem Statements</h2>
                            <p>Mountain Community Health Partnership operates 6 medical & dental clinics in western North Carolina. The medical partnership utilizes multiple medical personnel who work between various clinic locations. They approached WYN Technologies with a need to address security challenges they were facing in terms of managing entry/exit access control across their distributed service facilities.</p>
                            <ul>
                                <li>With multiple personnel working between clinics, the new access control system made it simple to assign permissions for entry based upon scheduling and designated level of authority.</li>
                                <li>In the event an individual left the client’s employment, the client was now able to cancel entry remotely without having to worry about retrieving physical keys from the former employee.</li>
                                <li>Assigning badges or fobs to employees provided for a more efficient means of entry into areas that are considered proprietary. This improved productivity as well, since the step of having to navigate physical door keys was removed from the entry/exit process.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Wyn Approach</h2>
                            <p>The WYN approach to resolving these challenges for the client was to begin by learning more specific details pertaining to the operation of these various service clinics. We took the time to speak with each clinic’s operational manager, in order to then be able to present a unified solution which would meet the needs of the organization as a whole.</p>
                            <ul>
                                <li>Site surveys of each clinic revealed a variety of door configurations made from both wood and metal.</li>
                                <li>Determined the best installation methods to allow for minimal disruption to the client and its patients.</li>
                                <li>Physical security – including the need for better camera surveillance and access control – was identified as a key issue.</li>
                                <li>The introduction of hosted/VOIP phone service was flagged as an opportunity to improve business communications for the client.</li>
                                <li>Ensuring that all IT hardware was staged and tested prior to installation was deemed important for meeting the needs of a healthcare provider.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Challenges</h2>
                            <p>Challenges included determining the best cable path in multi-level buildings, where and how to place keypad cutouts for outdoor concrete walls, and meeting indoor ADA compliancy requirements for navigating around indoor glass knee walls. Below, we share WYN’s approach to meeting each of these particular challenges.</p>
                            <ul>
                                <li>The keypads for outdoor access points were mounted by installing conduit down the outside walls, and then penetrating the building at a point high above the existing door frames.</li>
                                <li>The ADA compliancy challenge regarding keypad access was overcome by installing cables through the existing metal framework in a manner that wouldn’t compromise the quality of previous workmanship.</li>
                                <li>Regarding the multi-level facility cable pathway challenge, we were able to take advantage of stairwell ceiling spaces in order to install the cables to meet NEC standards.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Benefits</h2>
                            <p>Benefits of services we provided for MCHP Medical Clinics included having absolute control of facility access security in real-time, pre-programming employee levels of access to each clinic, being able to add and remove access instantly, and making access management of temporary employees and employees-in-training much easier.</p>
                            <ul>
                                <li>Comprehensive, real-time control of facility access & security.</li>
                                <li>Ability to lock down facilities instantly in the event of a safety threat or emergency.</li>
                                <li>Peace of mind knowing that only designated employees & vendors have access to your facility.</li>
                                <li>Placing access control on all exterior doors means no worry of unauthorized back door entry.</li>
                                <li>Access to bio-waste material limited only to those granted special authority within the access control system.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <CaseStudyFeatures />
                        </MarkdownContent>
                    </MarkdownContentWrapperInner>
                    </MarkdownContentWrapper>
                </CaseStudyWrapper>
            </div>
        </Section>
        <LetastArticlesBgWhite>
         <LetastArticles />
        </LetastArticlesBgWhite>
    </Layout>
  )
}
export default caseStudyPage